
















































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  nextTick,
  watch,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "PLN",
    },
    participantServices: {
      type: Array,
      required: false,
      default: [],
    },
    participant: {
      type: Object,
      required: false,
      default: {},
    },
  },
  setup(props, { root, emit }) {
    const { checkIfIsOnReserve } = useMisc({ root });
    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      table: false,
      loaded: false,
      additionalServices: [] as any[],
      selectItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      selectedServices: [] as any,
      reserveList: [] as string[],
      selectedServicesReserveList: [] as any,
    });

    const model = ref<{
      [key: string]: {
        [key: string]: { checked: boolean; amount: number; text: string };
      };
    }>({});

    const updateModel = () => {
      Object.entries(model.value).forEach(([groupId, group]) => {
        Object.keys(group).forEach((serviceId) => {
          const participantService: any = props.participantServices.find(
            (participantService: any) =>
              participantService.service.id === serviceId
          );
          if (participantService && !participantService.isOnReserveList) {
            model.value[groupId][serviceId] = {
              checked: participantService.service.isEnabled ?? false,
              amount: participantService.selectedAmount ?? 0,
              text: participantService.text ?? "",
            };
          } else {
            if (participantService && participantService.isOnReserveList)
              state.reserveList.push(participantService?.service.id);
          }
        });
      });
    };

    watch(() => props.participantServices, updateModel, { deep: true });

    const fetchAdditionalServices = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(
          `event/${root.$route.params.id}/additional-service-group/participant`,
          {
            params: { sortBy: ["name"], sortDesc: [false] },
          }
        )
        .then(({ data: { additionalServiceGroups } }) => {
          state.additionalServices = additionalServiceGroups
            .map((item: any) => ({
              additionalServices: item.additionalServices.sort(
                (a: any, b: any) => a.ordinalNumber - b.ordinalNumber
              ),
              ...item,
            }))
            .sort((a: any, b: any) => a.ordinalNumber - b.ordinalNumber);

          model.value = Object.fromEntries(
            state.additionalServices.map((item) => [
              item.id,
              Object.fromEntries(
                item.additionalServices.map((service: any) => [
                  service.id,
                  { checked: false, amount: 0, text: "" },
                ])
              ),
            ])
          );

          nextTick(updateModel);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.additionalServices = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchAdditionalServices);

    const isChecked = (group: string, service: string) =>
      !!model.value[group][service]?.checked;

    const isAnyChecked = (group: string) =>
      Object.values(model.value[group]).some((service) => service.checked);

    const unsetChecked = (group: string) => {
      Object.keys(model.value[group]).forEach((service) => {
        model.value[group][service].checked = false;
      });
    };

    const switchChecked = (group: string, service: string) => {
      if (
        state.additionalServices.find((item) => item.id === group)?.formType ===
        "multiple"
      ) {
        model.value[group][service].checked = !model.value[group][service]
          ?.checked;
      } else {
        unsetChecked(group);
        nextTick(() => (model.value[group][service].checked = true));
      }
    };

    const onModelUpdate = (model: any) => {
      const value: any[] = [];

      Object.values(model).forEach((group: any) =>
        Object.entries(group).forEach(([id, service]: any[]) => {
          if (service.checked && service.amount > 0)
            value.push({
              id: id,
              selectedAmount: service.amount,
              text: service.text || undefined,
              discountCodeValue: service.discountCodeValue || undefined,
            });
        })
      );

      state.selectedServices = value;
    };

    watch(model, onModelUpdate, { deep: true });

    const assignToReserveList = () => {
      const tempReserve = [] as any;
      state.reserveList.forEach((el: string) => {
        tempReserve.push({
          id: el,
          selectedAmount: 0,
          text: undefined,
          discountCodeValue: null,
        });
      });
      state.selectedServicesReserveList = tempReserve.reduce(
        (acc: any, cur: any) => {
          if (!acc.find((item: any) => item.id === cur.id)) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );
    };
    watch(() => state.reserveList, assignToReserveList, { deep: true });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc(
            "panel.event.participant.edit.serviceAmountExceeded"
          )}`;
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.participantAlreadyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const tempReserve = state.selectedServicesReserveList.reduce(
        (acc: any, cur: any) => {
          if (!acc.find((item: any) => item.id === cur.id)) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );

      const data = {
        additionalServices: state.selectedServices.concat(tempReserve),
      };

      state.loading = true;

      axiosInstance
        .put(
          `participant/${root.$route.params.ptid}/additional-service`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          state.error = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.participant.editInfo.participantUpdateSuccessfull"
            ),
          });
          emit("fetch");
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    return {
      state,
      model,
      isChecked,
      isAnyChecked,
      unsetChecked,
      switchChecked,
      onSubmit,
      checkIfIsOnReserve,
    };
  },
});
